<template>
  <section class="key-list">
    <div class="btn-wrap">
      <el-button size="small" type="primary" @click="addKeywords">创建</el-button>
    </div>
    <el-table :data="tableData" border @row-click="addList" ref="table" style="width: 100%">
      <el-table-column  :resizable="false" prop="name" align="center" label="主题" />
      <el-table-column  :resizable="false" prop="classification" align="center" label="主要分类" />
      <el-table-column  :resizable="false" prop="coreWords" align="center" label="核心词" />
      <el-table-column  :resizable="false" prop="homonymWords" align="center" label="同现词" />
      <el-table-column  :resizable="false" prop="exclusionWords" align="center" label="排除词" />
      <el-table-column  :resizable="false" prop="createTime" align="center" label="创建时间" width="180px"/>
      <el-table-column  :resizable="false" label="操作" align="center">
        <template scope="{ row }">
          <el-button type="text" @click="editKeywords(row.id)">修改</el-button>
          <el-button type="text" @click="deleteKeywords(row.id)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <section class="list-bottom">
      <span class="count">共{{count}}条记录</span>
      <el-pagination :current-page="currentPage" :page-sizes="[10, 20, 30, 50]" :page-size="limit" layout="total, sizes, prev, pager, next, jumper"
        :total="400">
      </el-pagination>
    </section>
    <el-dialog title="提示" :visible.sync="dialogVisible" width="30%" :before-close="handleClose">
      <span class="prompt"><i class="el-icon-warning"></i>确定删除？</span>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
      </span>
    </el-dialog>
  </section>
</template>

<script>
import eventHub from '@/utils/eventHub'
export default {
  mounted () {
    eventHub.$emit('msg', '关键词')
  },
  data () {
    return ({
      count: 2,
      tableData: [
        {
          id: 1,
          name: '万科地产高管',
          classification: '陈建忠',
          coreWords: '万科陈建忠',
          homonymWords: '陈建忠',
          exclusionWords: '地产',
          createTime: '2019-10-29 15:30:00'
        },
        {
          id: 2,
          name: '宁波万科',
          classification: '宁波传奇里',
          coreWords: '上海',
          homonymWords: '上海',
          exclusionWords: '房产',
          createTime: '2019-10-29 15:30:00'
        }
      ],
      currentPage: 1,
      dialogVisible: false
    })
  },
  methods: {
    addKeywords () {
      this.$router.push('/settings/project/keywords/add/')
    },
    editKeywords (id) {
      this.$router.push('/settings/project/keywords/edit/' + id)
    },
    deleteKeywords (id) {
      this.dialogVisible = true
    }
  }
}
</script>

<style lang="scss">
  .key-list {
    width: 100%;
    min-height: 100%;
    background: #fff;
    border-radius: 8px;
    padding: 20px 20px;
    box-shadow: 0px 1px 13px 0px rgba(0, 0, 0, 0.1);
    position: relative;

    .btn-wrap {
      margin-bottom: 25px;
    }

    .list-bottom {
      display: flex;
      justify-content: space-between;
      margin-top: 20px;
      /* position: absolute;
      bottom: 20px;
      left: 20px;
      right: 20px; */

      .count {
        line-height: 32px;
      }
    }

    .selected {
      background: rgba(64, 158, 255, .2);
    }

    .el-dialog {
      .prompt {
        height: 28px;
        display: flex;
        align-items: center;

        .el-icon-warning {
          font-size: 28px;
          color: #FF8B35;
          margin-right: 12px;
        }
      }
    }
  }
</style>
